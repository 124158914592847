.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  flex-direction: column;
  padding: 2em;
}

.loaderWrapper h2 {
  text-align: center;
  color: #183b72;
  font-weight: bold;
}

.loaderWrapper p {
  color: #555;
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  max-width: 65%;
}
