@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

h1 {
  font-weight: 900;
}

.app-wrapper {
  height: 100%;
  min-height: 100%;

  @apply min-h-screen;
}

.container-conteudo {
  min-height: 100vh;
  padding: 0 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  @apply p-0 lg:p-6 mx-auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.env-nome {
  @apply text-xs;
}

.ipt-text {
  @apply text-base mb-0 border bg-gray-50 border-gray-400 w-full shadow-sm rounded-lg text-gray-800 py-3 leading-6 focus:outline-none focus:border-secundaria;
}

.gradient-primaria {
  background: rgb(88, 156, 204);
  background: linear-gradient(
    135deg,
    rgba(88, 156, 204, 1) 0%,
    rgba(30, 84, 153, 1) 38%,
    rgba(24, 59, 114, 1) 100%
  );
}
.gradient-primaria:hover {
  background: rgb(18, 156, 204);
}

.gradient-secundaria {
  background: rgb(110, 231, 183);
  background: linear-gradient(
    135deg,
    rgb(73, 192, 144) 0%,
    rgba(16, 185, 129, 1) 38%,
    rgba(5, 150, 105, 1) 100%
  );
}

.gradient-secundaria:hover {
  background: rgb(5, 150, 1);
}

.gradient-terciaria {
  background: rgb(88, 156, 204);
}

.ipt-btn {
  @apply px-4 py-3 text-white shadow rounded-lg text-sm border-primaria cursor-pointer mb-0 text-base gradient-primaria border-0;
}

.ipt-btn-empty {
  @apply px-4 py-3 shadow border-2 rounded-lg text-primaria text-sm border-primaria cursor-pointer mb-0 text-base;
}

.ipt-btn-disabled {
  @apply px-4 py-3 shadow border-2 rounded-lg text-gray-300 text-sm border-gray-300 cursor-not-allowed mb-0 text-base;
}

.btn-wrap {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
}

.menu-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.menu-nav-btn {
  padding: 8px;
  border-radius: 6px;
}

.stepper-step {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  height: 3rem;
  width: 3rem;
}

/* .ipt-btn:hover {
  @apply border-secundaria cursor-pointer mb-0 text-base bg-secundaria border-0 transition-all !important;
}
 */
.ipt-btn-outline {
  @apply px-4 py-3 shadow rounded-lg text-sm cursor-pointer border-2 transition-all;
}

.ipt-btn.btn-green,
.swal2-confirm.swal2-styled {
  @apply hover:bg-cinza border-0 gradient-secundaria !important;
}

.ipt-btn.btn-blue {
  @apply hover:bg-cinza border-0 gradient-terciaria;
}

.btn-whats {
  border-width: 1px;
}

.btn-icon {
  @apply flex items-center justify-center;
}

.label-form {
  @apply mt-3 mb-0 leading-8;
  margin-top: 1rem;
}

.form-container {
  @apply bg-white rounded-lg shadow px-6 lg:px-12 py-5 lg:py-10;
}

.sep-vertical {
  @apply bg-cinza mx-3 opacity-50;
  height: 20px;
  width: 1px;
  display: block;
}

.btn-minhas {
  background: #059669;
}

.rounded-r-none {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-r-none {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-l-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

input::-webkit-input-placeholder {
  /* Edge */
  @apply text-gray-400;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @apply text-gray-400;
}

input::placeholder {
  @apply text-gray-400;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @apply min-h-screen;
}

.input-search::after {
  position: absolute;
  width: 100px;
  height: 50px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  right: 0;
  top: 0;
  content: "Filtro";
  display: block;
}

html {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #343434;
  font-family: Poppins;
  /* background: rgb(255, 255, 255); */
  /* background: #acc5d633; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(243, 244, 246, 0.6) 24%,
    rgba(243, 244, 246, 1) 100%
  );
  /* background: #e7efff91; */
}

div#__next {
  height: 100%;
  min-height: 100%;
}

a {
  text-decoration: none;
  @apply text-primaria hover:text-secundaria;
}

* {
  box-sizing: border-box;
}

.page-header:first-child {
  padding-top: 1.5em;
}

.filtroativo {
  border-bottom: 3px solid #183b72 !important;
}

.info-contrato h2 .iconeContrato {
  margin-left: 10px;
  display: block;
  float: right;
}

.pdf-contrato {
  width: auto;
  float: left;
  margin: auto !important;
  display: block;
  clear: both;
}

.contrato-container {
  height: 990px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2em;
  max-height: 500px;
  overflow: auto;
  width: auto;
  max-width: 900px;
  flex-direction: column;

  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.contrato-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.contrato-aviso {
  color: #e82b00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em auto 0.5em;
}

.contrato-aviso a {
  font-weight: bold;
  display: inline-block;
  margin: 0 4px;
  text-decoration: underline;
}

.info-conclusao {
  width: 500px;
  height: auto;
  padding: 1em;
  background: #5dcdec;
  margin: 1em auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.wrapper-banner-interno {
  background: url("/images/banner-interno.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5em;
}
.wrapper-banner-interno img {
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
}

.wrapper-banner-interno h1 {
  display: block;
  margin: auto;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  color: #fff;
}

.nav-contrato {
  clear: both;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

div.page-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
div.page-header h2 {
  color: #003373;
  margin: 0.7em auto;
}
.ramais-table {
  border: 1px solid #183b72;
  display: block;
}
.ramais-table thead {
  width: 100%;
  height: auto;
  background-color: #183b72;
  padding: 2px 5px 3px 5px;
  display: block;
  color: #fff;
  font-size: 14px;
}
.ramais-table tbody {
  width: 100%;
  display: block;
}
.ramais-table tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
}
.ramais-table tr:last-child {
  border-bottom: none;
}
.ramais-table td {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  color: #444;
  padding: 1px 2px;
}
.ramais-table td:last-child {
  border-left: 1px solid #183b72;
  padding: 1px 10px;
  background-color: #5597c5;
  color: #fff;
}

/* .swal2-styled.swal2-confirm {
  background-color: #22c55e;
} */

.confirma-assinatura {
  font-size: 16px;
}

.footer-absolute {
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-fadisma {
  padding: 1em;
  @apply bg-primaria w-full h-auto;
}

.footer-fadisma p {
  color: #fff;
  line-height: 14px;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
}

.separador {
  height: 100px;
  width: 1px;
  background: #e3e3e3;
}

.step-link span {
  @apply text-primaria;
}

.step-link > div {
  @apply bg-primaria text-white;
}

.step-link:hover span,
.active-link span {
  @apply text-secundaria;
}

.step-link:hover > div,
.active-link > div {
  @apply bg-secundaria text-white;
}

.bg-login {
  @apply bg-no-repeat bg-cover;
}

.hr-padrao {
  @apply my-4 lg:my-8;
  width: 100%;
  height: 1px;
}

h2 {
  @apply text-xl text-texto mb-4;
}

select {
  cursor: pointer;
}

.swal2-actions {
  margin-top: 0 !important;
}

.user-found {
  color: rgb(88, 156, 204);
  margin-top: 6px;
  line-height: 1.2em;
}

.selfie-container {
  margin-left: 1em;
}

.selfie-instrucao img {
  filter: grayscale(1);
}

.ipt-hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.ipt-visible {
  visibility: visible;
  opacity: 1;
  height: auto;
  margin-top: 5px;
}

.disabled-field {
  background-color: #efefef;
  color: #767676;
  cursor: not-allowed;
  pointer-events: none;
}

@media (max-width: 768px) {
  .footer-absolute {
    position: relative;
    bottom: unset;
    left: unset;
  }
  .app-footer {
    position: relative;
    top: unset;
    left: unset;
  }

  div#__next {
    height: auto;
    min-height: 100%;
  }
  .app-wrapper {
    height: auto;
    min-height: 100%;
  }
}
